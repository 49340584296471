<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :close-on-click="false"
    top
    right
    max-width="400"
    offset-x
    offset-y
    nudge-right="90"
    origin="top left"
    transition="scale-transition"
    rounded="0"
  >
    <template #activator="{ attrs, on }">
      <v-card
        id="settings"
        class="py-4 px-4 text-center d-flex flex-column align-center justify-center"
        color="#2799d6bb"
        dark
        tile
        flat
        style="position: fixed; top: 64px; left: 0px"
        width="340"
        v-bind="attrs"
        v-on="on"
      >
        <p class="mb-0 text-h6 text-uppercase"><b>SENZA TAVOLO</b></p>
      </v-card>
    </template>

    <v-card class="py-2 overflow-y-auto" max-height="65vh">
      <base-title align="center" :title="titleTavoli" space="0" />
      <v-card-text>
        <p class="text-body-2 mb-0">
          1. L'elenco mostra i winner (+ accompagnatori) che non hanno ancora
          scelto il tavolo.
        </p>
        <v-divider class="my-3" />
        <v-card
          flat
          class="px-2 py-1 text-caption mb-1 white--text text-uppercase d-flex align-center justify-space-between"
          :color="getGuestColor(guest)"
          v-for="guest in seating"
          :key="guest.idGuest"
          rounded="0"
        >
          <span> {{ guest.nominativo }}</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="guest.email !== email && guest.type === 'win'"
                v-bind="attrs"
                v-on="on"
                class="ml-auto"
                color="white"
                @click="removeGuest(guest.email)"
                >mdi-delete-circle
              </v-icon>
            </template>
            <span>Rimuovi dalla lista</span>
          </v-tooltip>
        </v-card>
        <v-divider class="my-3" />
        <v-card tile outlined class="pa-2">
          <v-autocomplete
            v-model="toAdd"
            v-if="seating.length < 10"
            :items="friendPool"
            item-text="nominativo"
            return-object
            label="Vuoi aggiungere altri ospiti?"
            persistent-hint
            hint="Seleziona gli ospiti che vuoi aggiungere al tuo tavolo"
            @input="addGuests"
          ></v-autocomplete>
          <p class="text-center text-body-1 mb-0" v-else>
            non puoi aggiungere altri ospiti al tuo tavolo
          </p>
        </v-card>
        <v-divider class="my-3" />
        <v-btn
          v-if="false"
          x-large
          class="mt-2"
          tile
          color="primary"
          @click="conferma"
          block
        >
          conferma e scegli tavolo
        </v-btn>
        <v-divider class="my-3" />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "GalaSettingsAdmin",
  created() {},
  data() {
    return {
      menu: false,
      seating: [],
      toAdd: { lista: [] },
    };
  },
  props: {
    turno: {
      type: String,
      default: "A",
    },
  },

  computed: {
    currentThemePrimary: {
      get() {
        return this.$vuetify.theme.currentTheme.primary;
      },
      set(val) {
        const target = this.$vuetify.theme.isDark ? "dark" : "light";

        this.$vuetify.theme.themes[target].primary = val;
      },
    },
    numeroTavoliLiberi() {
      return this.calcTableNum(this.seating.length + this.toAdd.lista.length);
    },
    postiRichiesti() {
      return this.seating.length == 1
        ? " un posto libero"
        : this.seating.length + " posti liberi";
    },
    titleTavoli() {
      return `${this.numeroTavoliLiberi} tavoli con almeno ${this.postiRichiesti}`;
    },
    color() {
      return this.$vuetify.isDark ? "white" : "grey lighten-5";
    },
    email() {
      return this.$store.getters[`user/email`];
    },
    freeSeats() {
      return this.$store.getters[`gala/getFreeSeats${this.turno}`];
    },
    freeTables() {
      return this.calcTableNum(0);
    },
    alreadySeated() {
      return this.$store.getters[`gala/getAlreadySeated${this.turno}`];
    },
    friendPool() {
      let possible = this.$store.getters[`gala/getGalaLW${this.turno}`].filter(
        (guest) => {
          return (
            !this.alreadySeated.includes(guest.email) &&
            this.canAdd(guest) &&
            guest.email !== this.email
          );
        }
      );
      function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }
      return possible.sort(dynamicSort("nominativo")).map((guest) => {
        return {
          ...guest,
          nominativo:
            guest.lista.length === 1
              ? guest.nominativo
              : guest.nominativo + " + " + (guest.lista.length - 1),
        };
      });
    },
  },
  methods: {
    canAdd(guest) {
      let freeTableSeats = 10 - this.seating.length;
      let alreadyIn = this.seating.filter((g) => g.email === guest.email);
      return guest.pax <= freeTableSeats && alreadyIn.length === 0;
    },
    guestcountPerMail(email) {
      return this.seating.filter((guest) => guest.email === email).length;
    },
    // conferma() {
    //   this.$store.dispatch("gala/inviaAdminBookTable", this.seating);
    //   this.menu = false;
    // },
    getGuestColor(guest) {
      if (guest.type === "win") return "accent";
      return "primary";
    },
    howManyTables(num) {
      return this.freeSeats[`free${num}`].length;
    },
    calcTableNum(num) {
      let tables = 0;
      for (let i = 10; i >= num; i--) {
        tables += this.howManyTables(i);
      }
      return tables;
    },
    reset() {
      this.seating = [];
      this.toAdd = { lista: [] };
    },
    addGuests() {
      this.toAdd.lista.forEach((guest) => {
        this.seating.push(guest);
      });
      // this.$store.dispatch("gala/refreshAdminBookTable", this.seating);
      this.toAdd = { lista: [] };
    },
    removeGuest(email) {
      this.seating = this.seating.filter((guest) => guest.email !== email);
      // this.$store.dispatch("gala/refreshAdminBookTable", this.seating);
    },
    annulla() {
      this.seating = [];
      this.toAdd = { lista: [] };
      // this.$store.dispatch("gala/resetAdminBookTable");
      this.$emit("close");
    },
  },
};
</script>
