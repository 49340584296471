import { firestoreAction } from "vuexfire";
import { db } from "@/firebase/init";

const namespaced = true;
const state = {
  settings: {},
  allowed: {},
};
const mutations = {
  SET_SETTINGS: (state, payload) => {
    state.settings = payload;
  },
};
const actions = {
  bindSettings: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "settings",
      db
        .collection("projects")
        .doc("regtool")
        .collection("settings")
        .doc("settings")
    );
  }),
  unbindSettings: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("settings");
  }),
  bindAllowed: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "allowed",
      db
        .collection("projects")
        .doc("regtool")
        .collection("settings")
        .doc("allowed")
    );
  }),
  unbindAllowed: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("allowed");
  }),
  updateSettingsFromState: async ({ state }) => {
    try {
      await db
        .doc(`projects/regtool/settings/settings`)
        .set(state.settings, { merge: true });
    } catch (error) {
      console.log(error);
    }
  },
  updateSettingsFromPayload: async (payload) => {
    try {
      await db
        .doc(`projects/regtool/settings/settings`)
        .set(payload, { merge: true });
    } catch (error) {
      console.log(error);
    }
  },
  setSettings({ commit }, payload) {
    commit("SET_SETTINGS", payload);
  },
  saveSettings({ commit, dispatch }, payload) {
    commit("SET_SETTINGS", payload);
    dispatch("updateSettingsFromState");
  },
  allowMoreUsers: async ({ state, dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    let toAllow = [...state.allowed.allowed, ...payload];
    try {
      await db
        .doc(`projects/regtool/settings/allowed`)
        .set({ allowed: toAllow });
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  removeAllowedUser: async ({ dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    try {
      await db
        .doc(`projects/regtool/settings/allowed`)
        .set({ allowed: payload });
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
};
const getters = {
  getSettings: (state) => {
    return state.settings;
  },
  wRules: (state) => {
    if (state.settings.regRules === undefined) {
      return {};
    }
    return state.settings.regRules;
  },
  getDate: (state) => {
    if (state.settings.date === undefined) {
      return {};
    }
    return state.settings.date;
  },
  getQuote: (state) => {
    if (state.settings.quote === undefined) {
      return {};
    }
    return state.settings.quote;
  },
  getQuotaViaggio: (state, getters) => (turno, nascita_data) => {
    const date = getters.getDate;
    const quote = getters.getQuote;
    if (Object.keys(date).length === 0) {
      return null;
    }
    if (Object.keys(quote).length === 0) {
      return null;
    }
    if (nascita_data > date[`infantTurno${turno}`]) {
      return quote.qinfant;
    }
    if (nascita_data > date[`childTurno${turno}`]) {
      return quote.qchild;
    }
    return quote.qstandard;
  },
  fattoreAssicurazione: (state, getters) => (assicurato) => {
    if (getters.getQuote.qass === undefined) return 1;
    if (assicurato) return (getters.getQuote.qass + 100) / 100;
    else return 1;
  },
  getSettingsPrivacy: (state) => {
    if (state.settings.privacy === undefined) {
      return {};
    }
    return state.settings.privacy;
  },
  getActOptions: (state, getters) => {
    if (getters.getSettings.activities === undefined) {
      return [];
    }
    return getters.getSettings.activities.options;
  },
  getTalentCat: (state, getters) => {
    if (getters.getSettings.activities === undefined) {
      return [];
    }
    return getters.getSettings.activities.talentCat;
  },
  getActItemsTipo: (state, getters) => {
    if (getters.getSettings.activities === undefined) {
      return [];
    }
    return getters.getSettings.activities.itemsTipo.sort();
  },
  getActItemsTurni: (state, getters) => {
    if (getters.getSettings.activities === undefined) {
      return [];
    }
    return getters.getSettings.activities.itemsTurni.sort();
  },
  getVoli: (state, getters) => {
    if (getters.getSettings.voli === undefined) {
      return {};
    }
    return getters.getSettings.voli;
  },
  getPeriodo: (state, getters) => (turno) => {
    if (getters.getDate) {
      if (getters.getDate[`periodo${turno}`] === undefined) {
        return "";
      } else return getters.getDate[`periodo${turno}`];
    } else return "";
  },
  getFlight: (state, getters) => (volo) => {
    if (getters.getVoli) {
      if (getters.getVoli[volo] === undefined) {
        return {};
      } else return getters.getVoli[volo];
    } else return {};
  },
  mainTitle: (state) => {
    if (state.settings.content === undefined) {
      return "Loading...";
    }
    return state.settings.content.mainTitle;
  },
  content: (state) => {
    if (state.settings.content === undefined) {
      return {};
    }
    return state.settings.content;
  },
  dateLimite: () => {
    const today = new Date().toISOString();
    const minimumDoc = "2024-11-04";
    return {
      oggi: today,
      minimumDocs: minimumDoc,
    };
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
