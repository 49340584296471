import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueDayjs from 'vue-dayjs-plugin'
import DashboardPlugin from "./plugins/dashboard-plugin";
import VueSweetalert2 from "vue-sweetalert2";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import "./plugins";
import { auth } from "@/firebase/init";
// import { VueReCaptcha } from 'vue-recaptcha-v3'

// For more options see below
// If you don't need the styles, do not connect
// import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false;

Vue.use(VueSweetalert2);
Vue.use(DashboardPlugin);
Vue.use(PerfectScrollbar);
Vue.use(VueDayjs)
// Vue.use(VueReCaptcha, { siteKey: '6LcAY4gpAAAAAOAF6IUF3Nx_zKxF1nVavM6L4m8W' })

Vue.filter("toCurrency", function (value) {
  var formatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  });
  if (typeof value === "string") {
    return formatter.format(Number(value))
  }
  if (typeof value !== "number") {
    return value;
  }
  return formatter.format(value);
});

let app = null;

auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
