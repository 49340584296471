<template>
  <v-row no-gutters class="px-4">
    <v-col>
      <p
        v-if="titolo"
        class="text-sm mb-1 text-capitalize text-body font-weight-bold text-center"
      >
        {{ titolo }}
      </p>
      <base-time-picker
        :label="label"
        :ora="ora"
        :color="color"
        @update="bubbleUp($event)"
      />

      <div
        v-if="bottom"
        class="text-end text-capitalize text-xs text-secondary font-weight-light"
      >
        {{ ora }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "TimePickerLine.",
  props: {
    titolo: {
      type: [String, null],
      default: null,
    },
    label: {
      type: [String, null],
      default: null,
    },
    ora: {
      type: [String, null],
      default: "08:00",
    },
    bottom: {
      type: [Boolean],
      default: false,
    },
    color: {
      type: [String, null],
      default: null,
    },
  },
  computed: {},
  data: () => ({}),
  methods: {
    bubbleUp(data) {
      this.$emit("update", data);
    },
  },
};
</script>
