<template>
  <v-badge :content="freeSeats" :value="showBadge" :color="badgeColor" overlap>
    <v-menu open-on-hover :right="isRight" :left="isLeft" offset-x rounded="0">
      <template v-slot:activator="{ on, attrs }">
        <v-card
          v-on="on"
          v-bind="attrs"
          :style="tavoloStyle"
          class="text-center d-flex flex-column justify-center"
          :color="color"
          elevation="5"
        >
          <div class="text-h5 white--text">{{ numeroTavolo }}</div>
        </v-card>
      </template>
      <v-card flat rounded="0" v-if="!reserved">
        <v-card
          flat
          class="pa-2 text-caption"
          :color="getGuestColor(guest)"
          v-for="guest in seating"
          :key="guest.idGuest"
          rounded="0"
        >
          <span class="white--text text-uppercase">
            {{ guest.nominativo }}</span
          >
        </v-card>
        <v-card
          flat
          class="pa-2"
          :color="labelColor"
          v-if="freeSeats && !reserved"
          rounded="0"
        >
          <span :class="labelTextColor">
            {{
              freeSeats + (freeSeats > 1 ? " posti liberi" : " posto libero")
            }}</span
          >
        </v-card>
      </v-card>
    </v-menu>
  </v-badge>
</template>

<script>
export default {
  name: "GalaTavoloAdmin",
  data: () => ({
    confirmDialog: false,
  }),
  computed: {
    showBadge() {
      if (this.reserved) return false;
      if (this.freeSeats === 12) return false;
      if (this.freeSeats > 0) return true;
      return "";
    },
    badgeColor() {
      if (this.freeSeats > 6) return "primary";
      if (this.freeSeats > 2) return "accent";
      if (this.freeSeats > 0) return "red darken-3";
      return "red";
    },
    bookTableList() {
      return this.$store.getters["gala/getBookTableList"];
    },
    isRight() {
      return this.position === "left" ? false : true;
    },
    isLeft() {
      return this.position === "left" ? true : false;
    },
    freeSeats() {
      return this.maxPersone - this.postiOccupati;
    },
    labelColor() {
      if (this.freeSeats >= 0) return "white";
      return "grey darken-2";
    },
    labelTextColor() {
      if (this.freeSeats >= 0) return "black--text text-uppercase text-caption";
      return "white--text text-uppercase text-caption";
    },
    tavoloStyle() {
      return {
        width: this.width,
        height: this.height,
        "border-radius": "200px",
        cursor: this.pointerStyle,
      };
    },
    numeroTavolo() {
      return this.idTavolo.substring(1);
    },
    postiOccupati() {
      return this.seating.length;
    },
    pointerStyle() {
      return "help";
    },
    color() {
      if (this.tabColor) return this.tabColor;
      if (this.reserved) return "primary";
      if (this.postiOccupati === 0) return "orange darken-1";
      if (this.postiOccupati === 10) return "teal darken-3";
      if (this.postiOccupati < this.maxPersone) return "green darken-1";
      if (this.postiOccupati === this.maxPersone) return "accent";
      return "grey";
    },
  },
  methods: {
    getGuestColor(guest) {
      if (guest.type === "win") return "accent";
      return "primary";
    },
  },
  props: {
    maxPersone: {
      type: Number,
      default: 10,
    },
    position: String,
    idTavolo: {
      type: String,
      default: "",
    },
    seating: {
      type: Array,
      default: () => [],
    },
    reserved: {
      type: Boolean,
      default: false,
    },
    tabColor: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "70px",
    },
    height: {
      type: String,
      default: "70px",
    },
  },
};
</script>
