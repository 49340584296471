const allergeni = [
  {
    text: "Nessuna allergia / altro",
    descrizione: `Scegli questa voce se non hai nessuna allergia.`,
    value: "no allergy",
  },
  {
    text: "Cereali e derivati (glutine)",
    descrizione: `vengono considerati allergeni tutti i cereali contenenti glutine come grano, segale, orzo, avena, farro, kamut.
L'elenco si estende anche ai loro ceppi ibridati e ai prodotti derivati.`,
    value: "Cereals and derivatives",
  },
  {
    text: "Crostacei",
    descrizione: `vengono considerati allergeni le proteine provenienti da gamberi, gamberetti, scampi, granchi, aragoste, astici.
Ovviamente bisogna evitare anche i prodotti che contengono ingredienti derivati dai crostacei.`,
    value: "Shellfish",
  },
  {
    text: "Uova",
    descrizione: `considerate allergeni sia cotte che crude, ed anche se presenti in prodotti derivati come: pasta all'uovo, biscotti, torte, frittate, maionese, creme, cibi panati, sformati, ecc.`,
    value: "Eggs",
  },
  {
    text: "Pesce",
    descrizione: `l'allergia si può manifestare per tutti i tipi di pesce e per i prodotti derivati, fatta eccezione per gelatina di pesce utilizzata come supporto per preparati di vitamine o come chiarificante nella birra e nel vino.`,
  },
  {
    text: "Arachidi",
    descrizione: `le principali fonti di allergeni sono i prodotti derivati come l'olio di arachidi, il burro di arachidi, la farina di arachidi, il latte di arachide utilizzati come ingrediente per creme, snack, torroni ecc`,
    value: "Peanuts",
  },
  {
    text: "Soia",
    descrizione: `le proteine fonti di allergie sono presenti in tutti prodotti a base di soia, fatta eccezione per: olio e grasso di soia raffinato, tocoferoli misti naturali, tocoferolo D-alfa naturale, tocoferolo acetato D-alfa naturale, tocoferolo succinato D-alfa naturale a base di soia, oli vegetali derivati da fitosteroli e fitosteroli esteri a base di soia, estere di stanolo vegetale prodotto da steroli di olio vegetale a base di soia.`,
    value: "Soy",
  },
  {
    text: "Latte",
    descrizione: `e i prodotti a base di latte o di lattosio, fatta eccezione siero di latte utilizzato per la fabbricazione di distillati alcolici e il lattiolo.`,
    value: "Dairy products",
  },
  {
    text: "Frutta a guscio",
    descrizione: `ovvero mandorle, nocciole, noci, noci di acagiù, noci di pecan, noci del Brasile, pistacchi, noci macadamia e tutti i prodotti da essi derivati,
fatta eccezione per quelli utilizzati per la fabbricazione di distillati alcolici.`,
    value: "Nuts",
  },
  {
    text: "Sedano",
    descrizione: `che sia presente in pezzi o in prodotti derivati come preparati per zuppe, salse e concentrati vegetali.`,
    value: "Celery",
  },
  {
    text: "Senape",
    descrizione: `allergene che si può ritrovare tra gli ingredienti principali di salse e condimenti e soprattutto nella mostarda`,
    value: "Mustard",
  },
  {
    text: "Sesamo",
    descrizione: `spesso i semi interi sono usati per la preparazione del pane, ma spesso si riscontrano tracce di sesamo in alcuni tipi di farine;`,
    value: "Sesame",
  },
  {
    text: "Anidride solforosa e solfiti",
    descrizione: `solo se in concentrazioni superiori a 10 mg/kg o 10 mg/l espressi come SO2 (usati come conservanti) vengono riscontrati in conserve di prodotti ittici, cibi sott'aceto, cibi sott'olio e in salamoia, marmellate,aceto, funghi secchi, bibite analcoliche e succhi di frutta.`,
    value: "Sulphur dioxide and sulphites",
  },
  {
    text: "Lupini",
    descrizione: `presente ormai in molti cibi vegan, sotto forma di arrosti, salamini, farine e similari che hanno come base questo legume, ricco di proteine`,
    value: "Lupins",
  },
  {
    text: "Molluschi",
    descrizione: `presenti in piatti a base di canestrello, cannolicchio, capasanta, dattero di mare, fasolaro, garagolo, lumachino, cozza, murice, ostrica, patella, tartufo di mare, tellina e vongola, o nei derivati degli stessi.`,
    value: "Molluscs",
  },
];
const preferenze = [
  { text: "Vegano", value: "vegan" },
  { text: "Vegetariano", value: "vegetarian" },
];

export default {
  data: () => ({
    allergeni: allergeni,
    preferenze: preferenze,
  }),
};
