function calcDate(titolo) {
  let options = {
    year: "2-digit",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  let called = new Date().toLocaleString("it-IT", options);
  return `BKR_EMEA_2024-${titolo} - ` + called;
}
function dynamicSort(property) {
  // ordino in modo decrescente, ho invertito i valori di sortOrder
  var sortOrder = -1;
  if (property[0] === "-") {
    sortOrder = 1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0;
    return result * sortOrder;
  };
}

export { calcDate, dynamicSort };
