import { firestoreAction } from "vuexfire";
import { db, fv, union, auth } from "@/firebase/init";
import Swal from "sweetalert2/dist/sweetalert2";
import router from "./../../router";

const namespaced = true;
const state = {
  settings: {},
  pSettings: {},
  id: null,
  role: "notLogged",
  email: null,
  isLogged: false,
  guests: [],
  currentProject: "empty",
};
const mutations = {
  SET_SETTINGS: (state, payload) => {
    state.settings = payload;
  },
  SET_USER_AUTH: (state, payload) => {
    state.id = payload.id;
    state.role = payload.role;
    state.email = payload.email;
    state.isLogged = payload.isLogged;
  },
  SET_CURRENT_PROJECT: (state, payload) => {
    state.currentProject = payload;
  },
};
const actions = {
  setCurrentProject: (context, payload) => {
    context.commit("SET_CURRENT_PROJECT", payload);
    context.dispatch("bindMultisettings", payload);
    if (context.state.role === "admin" || context.state.role === "editor") {
      context.dispatch("bindGuestsData");
    }
  },
  updateSettingsFromState: async ({ state }) => {
    try {
      await db
        .doc(`projects/${state.currentProject}/settings/settings`)
        .set(state.settings, { merge: true });
    } catch (error) {
      console.log(error);
    }
  },
  setSettings({ commit }, payload) {
    commit("SET_SETTINGS", payload);
  },
  saveSettings({ commit, dispatch }, payload) {
    commit("SET_SETTINGS", payload);
    dispatch("updateSettingsFromState");
  },
  logout: () => {
    auth.signOut().then(() => {
      router.push(`/${state.currentProject}`);
    });
  },
  login: (context, user) => {
    return auth
      .signInWithEmailAndPassword(user.email, user.password)
      .then((userCredentials) => {
        userCredentials.user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            router.push(`/${user.project}/admin`);
          } else if (idTokenResult.claims.editor) {
            router.push(`/${user.project}/admin`);
          } else {
            router.push(`/${user.project}`);
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            err.code === "auth/wrong-password"
              ? "Wrong password"
              : err.code === "auth/too-many-requests"
              ? "Too many requests. Try again later"
              : "Generic error: " + err.code,
          confirmButtonText: "Try again",
          footer:
            'Having troubles? Write toa&nbsp;<a href="mailto:it_group@mutika.it?subject=Not%20allowed">it_group@mutika.it</a>',
        });
      });
  },
  authAction(context) {
    auth.onAuthStateChanged((user) => {
      if (user) {
        let role;
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            role = "admin";
          } else if (idTokenResult.claims.editor) {
            role = "editor";
          } else role = "user";
          context.commit("SET_USER_AUTH", {
            id: user.uid,
            email: user.email,
            role: role,
            isLogged: true,
          });
          if (role === "admin" || role === "editor") {
            context.dispatch("bindGuestsData");
          }
        });
      } else {
        context.commit("SET_USER_AUTH", {
          id: null,
          email: null,
          role: "notLogged",
          isLogged: false,
          currentProject: "empty",
        });
        context.dispatch("unbindGuestsData");
      }
    });
  },
  bindGuestsData: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "guests",
      db
        .collection("multiprojects")
        .doc(state.currentProject)
        .collection("guests")
    );
  }),
  unbindGuestsData: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("guests");
  }),
  bindMultisettings: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "settings",
      db
        .collection("multiprojects")
        .doc(state.currentProject)
        .collection("settings")
        .doc("settings")
    );
  }),
  unbindMultisettings: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("settings");
  }),
  bindMultisettingsByProject: firestoreAction((context, payload) => {
    return context.bindFirestoreRef(
      "settings",
      db
        .collection("multiprojects")
        .doc(payload)
        .collection("psettings")
        .doc("settings")
    );
  }),
  unbindMultisettingsByProject: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("psettings");
  }),
  saveData: firestoreAction((context, payload) => {
    let primary = payload.guests[0];
    let roomguests =
      payload.selectedPax === 1 ? "for one guest" : "for two guests";
    let people = "";
    payload.guests.forEach((guest, ind) => {
      people += `<li><b>Guest ${ind + 1}:</b><br><b>${guest.name} ${
        guest.lastname
      }</b><br>
      <b>Passport: </b>${guest.passport} <b>Exp. date: </b>${
        guest.passportExp
      }<br><b>E-mail:</b> ${guest.email} <b>Phone :</b>${guest.phone}</li>`;
    });
    let extraStayText = payload.selectedExtraStay
      ? payload.selectedExtraStay === "true"
        ? "You selected Yes for Extra stay<br>"
        : ""
      : "";
    let extraBedText = payload.selectedExtraBed
      ? payload.selectedExtraBed === "true"
        ? "You selected Yes for Extra bed<br>"
        : ""
      : "";
    let extraText = extraStayText + extraBedText;
    let extras = extraText ? extraText + "<br>" : "";
    let html = `<h3>Hotel reservation - BKR EMEA 2024 MILAN | ITALY</h3>
    Dear ${primary.name} ${primary.lastname}, we are glad to inform you we received your hotel reservation for the upcoming event<br> <b>BKR EMEA 2024 ANNUAL REGIONAL CONFERENCE</b><br>
    <br>
    We reserved you room for ${roomguests}:<br>
    <ul>${people}</ul><br><br>
    <hr>
    ${extras}
    <h3>Due total: ${payload.price}<h3>
    <i>Billing address:</i><br>${primary.address}<br>
    <br>
    You will receive another email in the next few days with instructions to finalize your booking.<br><br>
    Greetings, your Mutika team.
    `;
    let data = {
      sender: "bkremea2024registration@mutikart.com",
      bcc: "andrea.disanto@gmail.com",
      subject: "Hotel reservation - BKR EMEA 2024 MILAN | ITALY",
      text: `confirmation email`,
      html: html,
      replyTo: "bkr.2024.conference@mutika.it",
      selectedRoom: payload.selectedRoom,
      pax: payload.selectedPax,
      extraBed: payload.selectedExtraBed ? payload.selectedExtraBed : false,
      extraStay: payload.selectedExtraStay ? payload.selectedExtraStay : false,
      guests: payload.guests,
    };
    return db
      .collection("multiprojects")
      .doc(payload.project)
      .collection("guests")
      .doc(payload.guests[0].email)
      .set(data);
  }),
  saveDataTransaction: firestoreAction((context, payload) => {
    let primary = payload.guests[0];
    let roomguests =
      payload.selectedPax === 1 ? "for one guest" : "for two guests";
    let people = "";
    payload.guests.forEach((guest, ind) => {
      people += `<li><b>Guest ${ind + 1}:</b><br><b>${guest.name} ${
        guest.lastname
      }</b><br>
      <b>Passport: </b>${guest.passport} <b>Exp. date: </b>${
        guest.passportExp
      }<br><b>E-mail: </b>${guest.email} <b>Phone: </b>${guest.phone}<br></li>`;
    });
    let extraStayText = payload.selectedExtraStay
      ? payload.selectedExtraStay === "true"
        ? "You selected Yes for Extra stay<br>"
        : ""
      : "";
    let extraBedText = payload.selectedExtraBed
      ? payload.selectedExtraBed === "true"
        ? "You selected Yes for Extra bed<br>"
        : ""
      : "";
    let extraText = extraStayText + extraBedText;
    let extras = extraText ? extraText + "<br>" : "";
    let html = `<h4>Hotel reservation - BKR EMEA 2024 MILAN | ITALY</h4>
    <p style="font-size:1rem">Dear ${primary.name} ${primary.lastname}, we are glad to inform you we received your hotel reservation for the upcoming BKR EMEA 2024 ANNUAL REGIONAL CONFERENCE.<br><br>
    We reserved you a room ${roomguests} at<br><br>
<b>Grand Visconti Palace Hotel</b><br>
<i>Viale Isonzo, 14 - 20135 Milan - Italy</i><br><br>
    <b>Check-in:</b> May 31st, 2024 - <b>Check-out:</b> Jun 3rd, 2024</p>
        <ul>${people}</ul>
    <p style="font-size:1rem">
        ${extras}</p>
        <h3>Due total: ${payload.price}</h3>
<p style="font-size:0.9ren">
<b>not included:</b>
Milan Hotel City tax. You should pay directly to the hotel.
</p>

        <p>You will receive another email in the next few days with instructions to finalize your booking.<br>
        Greetings, your Mutika team.</p>
        <hr>
        <p style="font-size:0.85rem"><i>The information in this communication and any documents hereby attached are confidential and are intended for the relevant addressee only. Any dissemination, distribution and/or copy of the information and documents by any subject other than the relevant addressee is forbidden, both under sec. 616 of Italian Criminal Code, the Legislative Decree 196/2003 and the Regulation EU 679/2016. If you have received this message by mistake, please delete it and promptly inform us by sending an e-mail to privacy@mutika.it</i></p>`;
    let data = {
      sender: "bkremea2024hotelreservation@mutikart.com",
      bcc: "bkr.2024.conference@mutika.it, reservationsbkr@mutikart.com",
      subject: "Hotel reservation - BKR EMEA 2024 MILAN | ITALY",
      text: `confirmation email`,
      html: html,
      replyTo: "bkr.2024.conference@mutika.it",
      selectedRoom: payload.selectedRoom,
      pax: payload.selectedPax,
      extraBed: payload.selectedExtraBed ? payload.selectedExtraBed : false,
      extraStay: payload.selectedExtraStay ? payload.selectedExtraStay : false,
      bookedAt: payload.bookedAt,
      guests: payload.guests,
    };
    db.runTransaction((transaction) => {
      return transaction
        .get(
          db
            .collection("multiprojects")
            .doc(payload.project)
            .collection("settings")
            .doc("settings")
        )
        .then((doc) => {
          if (!doc.exists) {
            throw "Document does not exist!";
          }
          let settings = doc.data();
          let room = settings.rooms.find(
            (room) => room.id === payload.selectedRoom
          );
          if (settings.reservations.includes(payload.guests[0].email)) {
            throw "Already reserved";
          }
          if (room.number <= settings[`res${payload.selectedRoom}`]) {
            throw "Room not available";
          }
          if (
            room.number > settings[`res${payload.selectedRoom}`] &&
            !settings.reservations.includes(payload.guests[0].email)
          ) {
            transaction.update(
              db
                .collection("multiprojects")
                .doc(payload.project)
                .collection("settings")
                .doc("settings"),
              { [`res${payload.selectedRoom}`]: fv.increment(1) }
            );
            transaction.update(
              db
                .collection("multiprojects")
                .doc(payload.project)
                .collection("settings")
                .doc("settings"),
              { reservations: union(payload.guests[0].email) }
            );
            transaction.set(
              db
                .collection("multiprojects")
                .doc(payload.project)
                .collection("guests")
                .doc(payload.guests[0].email),
              data
            );
          }
        });
    })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Reservation confirmed!",
          text: "You will receive a confirmation email shortly",
          confirmButtonText: "Close",
          confirmButtonColor: "#00755e",
        }).then(() => {
          router.push(`/${payload.project}/confirmed`);
        });
      })
      .catch((error) => {
        if (error === "Already reserved") {
          Swal.fire({
            title: "Error!",
            html: `<p class="text-center">You already reserved a room!<br>Please, contact us at<br> <a href="mailto:bkr.2024.conference@mutika.it">bkr.2024.conference@mutika.it</a><br>if you need assistence.</p>`,
            icon: "error",
            confirmButtonText: "Ok",
          });
          return;
        }
        if (error === "Room not available") {
          Swal.fire({
            title: "Error!",
            html: `<p class="text-center">This room is not available anymore.<br>Please, contact us <a href="mailto:bkr.2024.conference@mutika.it">bkr.2024.conference@mutika.it</a></p>`,
            icon: "error",
            confirmButtonText: "Ok",
          });
          return;
        }
        if (error === "Document does not exist!") {
          Swal.fire({
            title: "Error!",
            text: "Error connection to the server. Please, try again",
            icon: "error",
            confirmButtonText: "Ok",
          });
          return;
        }
        Swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  }),
  rateLoginPwless: (context, payload) => {
    return auth
      .sendSignInLinkToEmail(payload.email, {
        // url: `https://mutikart.com/${payload.project}/llinkvalidation`,
        url: `http://localhost:5500/${payload.project}/llinkvalidation`,
        handleCodeInApp: true,
      })
      .then(() => {
        window.localStorage.setItem("emailForSignIn", payload.email);
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
const getters = {
  getRole: (state) => {
    return state.role;
  },
  getCurrentProject: (state) => {
    return state.currentProject;
  },
  getGuests: (state) => {
    let guests = state.guests;
    return guests.map((guest) => {
      let ppl = guest.guests;
      return {
        pax: guest.guests.length,
        booked: guest.bookedAt ? guest.bookedAt : "",
        name: ppl[0].name,
        lastname: ppl[0].lastname,
        email: ppl[0].email,
        phone: ppl[0].phone,
        passport: ppl[0].passport,
        passportExp: ppl[0].passportExp,
        billingAddress: ppl[0].address ? ppl[0].address : "",
        guest_name: ppl[1] ? ppl[1].name : "",
        guest_lastname: ppl[1] ? ppl[1].lastname : "",
        guest_email: ppl[1] ? ppl[1].email : "",
        guest_phone: ppl[1] ? ppl[1].phone : "",
        guest_passport: ppl[1] ? ppl[1].passport : "",
        guest_passportExp: ppl[1] ? ppl[1].passportExp : "",
        extraBed: guest.extraBed === "true" ? true : false,
        extraStay: guest.extraStay === "true" ? true : false,
      };
    });
  },
  getSettings: (state) => {
    if (state.settings.drawer === undefined) {
      return {};
    }
    return state.settings;
  },
  allowed: (state) => {
    if (state.settings.allowed === undefined) {
      return [];
    }
    return state.settings.allowed;
  },
  getRooms: (state) => {
    if (state.settings.rooms === undefined) {
      return [];
    }
    return state.settings.rooms.map((room) => {
      return {
        ...room,
        reservations: state.settings[`res${room.id}`]
          ? state.settings[`res${room.id}`]
          : 0,
      };
    });
  },
  getRoomState: (state, getters) => (roomId) => {
    if (state.settings.rooms === undefined) {
      return {};
    }
    let room = state.settings.rooms.find((room) => room.id === roomId);
    let reservations = getters.getRoomReservations(roomId);
    return { ...room, reservations: reservations };
  },
  getRoomReservations: (state) => (roomId) => {
    if (state.settings[`res${roomId}`] === undefined) {
      return 0;
    }
    return state.settings[`res${roomId}`];
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
