import dayjs from "dayjs";

function creaSiglaDaCodice(stringaDiCifre) {
  let stringaDiLettere = "mw24";
  for (let i = 0; i < stringaDiCifre.length; i++) {
    const cifra = parseInt(stringaDiCifre[i]);
    let lettera = String.fromCharCode(cifra + 105);
    stringaDiLettere += lettera;
  }
  return stringaDiLettere;
}

function formatDateIta(data) {
  if (data === "") return "";
  if (data === "nndd") return "nndd";
  return new Date(data).toLocaleDateString("it-IT", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

function formatDateEnShort(data) {
  if (data === "") return "";
  if (data === "nndd") return "nndd";
  return new Date(data).toLocaleDateString("en-EN", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
}

function arrToStringa(arr) {
  let stringa = "";
  if (arr.length > 0) {
    arr.forEach((allergia, index) => {
      stringa += allergia;
      if (index !== arr.length - 1) {
        stringa += " ";
      }
    });
  }
  return stringa;
}

function convertWinner(obj) {
  let mancante = "nndd";
  let quota = obj.quota ? obj.quota : 0;
  let accompagnatori = obj.lista_acc
    ? obj.lista_acc.length
      ? obj.lista_acc.map((acc) => convertAcc(acc, obj))
      : []
    : [];
  let preferenze_camera = obj.preferenze_camera
    ? obj.preferenze_camera.join(", ")
    : "";
  let sigla = obj.codice_agente
    ? creaSiglaDaCodice(obj.codice_agente)
    : "no-sigla";
  let allergie = obj.alimentari_allergie
    ? obj.alimentari_allergie.join(", ") === "no allergy"
      ? ""
      : obj.alimentari_allergie.join(", ")
    : "";
  let alimentari_preferenze = obj.alimentari_preferenze
    ? obj.alimentari_preferenze
    : "";
  // let alimentari_preferenze = obj.alimentari_preferenze
  //   ? obj.alimentari_preferenze.join(", ")
  //   : "";
  let acc = obj.lista_acc ? obj.lista_acc.length : 0;
  return {
    codice_agente: obj.codice_agente ? obj.codice_agente : mancante,
    email: obj.email ? obj.email : mancante,
    id: obj.id ? obj.id : obj.email ? obj.email : "no-id",
    type: "winner",
    nome: obj.nome ? obj.nome : mancante,
    cognome: obj.cognome ? obj.cognome : mancante,
    ordinatore:
      (obj.cognome ? obj.cognome : "") + " " + (obj.nome ? obj.nome : ""),
    turno: obj.turno ? obj.turno : mancante,
    pax: 1 + acc,
    premio: obj.premio ? obj.premio : "no-premio",
    acc: acc,
    maxAcc: obj.max_accompagnatori ? obj.max_accompagnatori : 0,
    volo_andata: obj.volo_andata ? obj.volo_andata : "",
    volo_ritorno: obj.volo_ritorno ? obj.volo_ritorno : "",
    aeroporto: obj.sigla_aeroporto ? obj.sigla_aeroporto : "",
    wa: obj.wa ? obj.wa : "",
    vip: obj.vip ? obj.vip : "",
    hotel: obj.hotel ? obj.hotel : "",
    n_of_rooms: obj.n_of_rooms ? obj.n_of_rooms : "",
    room_number: obj.room_number ? obj.room_number : "",
    room_type: obj.room_type ? obj.room_type : "",
    nascita_data: obj.nascita_data ? obj.nascita_data : mancante,
    nascita_comune: obj.nascita_comune ? obj.nascita_comune : mancante,
    nascita_provincia: obj.nascita_provincia ? obj.nascita_provincia : mancante,
    nascita_stato: obj.nascita_stato ? obj.nascita_stato : mancante,
    cellulare: obj.cellulare ? obj.cellulare : mancante,
    doc: obj.doc_tipo ? obj.doc_tipo : mancante,
    doc_stato: obj.doc_stato ? obj.doc_stato : mancante,
    doc_num: obj.doc_numero ? obj.doc_numero : mancante,
    doc_rilas: obj.doc_luogo ? obj.doc_luogo : mancante,
    doc_data_ril: obj.doc_data ? obj.doc_data : mancante,
    doc_scad: obj.doc_scad ? obj.doc_scad : mancante,
    cod_fiscale: obj.codice_fiscale ? obj.codice_fiscale : mancante,
    alimentari_allergie: allergie,
    alimentari_preferenze: alimentari_preferenze,
    alimentari_note: obj.note_alimentari ? obj.note_alimentari : "",
    gala: obj.galaTable ? obj.galaTable : "",
    fatt_indirizzo: obj.fatt_indirizzo ? obj.fatt_indirizzo : mancante,
    fatt_cap: obj.fatt_cap ? obj.fatt_cap : mancante,
    fatt_citta: obj.fatt_citta ? obj.fatt_citta : mancante,
    fatt_prov: obj.fatt_prov ? obj.fatt_prov : mancante,
    fatt_sdi: obj.fatt_sdi ? obj.fatt_sdi : mancante,
    fatt_pec: obj.fatt_pec ? obj.fatt_pec : mancante,
    partita_iva: obj.partita_iva ? obj.partita_iva : mancante,
    lista_acc: accompagnatori,
    agenda: obj.agenda ? obj.agenda : [],
    rinuncia: obj.rinuncia ? obj.rinuncia : "no",
    registrato: obj.prima_scelta ? "SI" : "NO",
    conferma: obj.viaggio_confermato
      ? obj.viaggio_confermato
      : "non confermato",
    req_dus: obj.req_dus ? obj.req_dus : "",
    quota: quota,
    quataAss: obj.quotaAss ? obj.quotaAss : 0,
    quotaFeeCard: obj.quotaFeeCard ? obj.quotaFeeCard : 0,
    noshow: obj.noshow ? obj.noshow : "",
    sigla_agente: sigla,
    pagamento: obj.pagamento ? obj.pagamento : "",
    pag_ricevuto: obj.conferma_di_pagamento
      ? obj.conferma_di_pagamento
      : obj.quota
      ? "no"
      : "non pagante",
    preferenze_camera: preferenze_camera,
    note: obj.note ? obj.note : "",
    note_elisa: obj.note_elisa ? obj.note_elisa : "",
    note_mediolanum: obj.note_mediolanum ? obj.note_mediolanum : "",
    ageA: obj.nascita_data
      ? dayjs(obj.turno === "A" ? "2024-09-13" : "2024-09-20").diff(
          obj.nascita_data,
          "year"
        )
      : "nn",
    ageR: obj.nascita_data
      ? dayjs(obj.turno === "A" ? "2024-09-20" : "2024-09-27").diff(
          obj.nascita_data,
          "year"
        )
      : "nn",
  };
}
function convertAcc(obj, win) {
  let hotel = obj.hotel ? obj.hotel : win.hotel ? win.hotel : "";
  let volo_andata = obj.volo_andata
    ? obj.volo_andata
    : win.volo_A
    ? win.volo_A
    : win.volo_andata
    ? win.volo_andata
    : "";
  let volo_ritorno = obj.volo_ritorno
    ? obj.volo_ritorno
    : win.volo_R
    ? win.volo_R
    : win.volo_ritorno
    ? win.volo_ritorno
    : "";
  let turno = obj.turno ? obj.turno : win.turno ? win.turno : "";
  let mancante = "acc-nndd";
  let bloccante = "acc-blocc";
  let allergie = obj.alimentari_allergie
    ? obj.alimentari_allergie.join(", ") === "no allergy"
      ? ""
      : obj.alimentari_allergie.join(", ")
    : "";
  let alimentari_preferenze = obj.alimentari_preferenze
    ? obj.alimentari_preferenze
    : "";
  // let alimentari_preferenze = obj.alimentari_preferenze
  //   ? obj.alimentari_preferenze.join(", ")
  //   : "";
  return {
    id: obj.id ? obj.id : "no-id",
    nome: obj.nome ? obj.nome : mancante,
    cognome: obj.cognome ? obj.cognome : mancante,
    ordinatore:
      (win.cognome ? win.cognome : "") + " " + (win.nome ? win.nome : ""),
    genere: obj.genere ? obj.genere : mancante,
    nascita_data: obj.nascita_data ? obj.nascita_data : bloccante,
    nascita_comune: obj.nascita_comune ? obj.nascita_comune : mancante,
    nascita_provincia: obj.nascita_provincia ? obj.nascita_provincia : mancante,
    nascita_stato: obj.nascita_stato ? obj.nascita_stato : mancante,
    cod_fiscale: obj.codice_fiscale ? obj.codice_fiscale : mancante,
    email: obj.email ? obj.email : win.email ? win.email : mancante,
    doc: obj.doc_tipo ? obj.doc_tipo : mancante,
    doc_stato: obj.doc_stato ? obj.doc_stato : mancante,
    doc_num: obj.doc_numero ? obj.doc_numero : mancante,
    doc_rilas: obj.doc_luogo ? obj.doc_luogo : mancante,
    doc_data_ril: obj.doc_data ? obj.doc_data : mancante,
    doc_scad: obj.doc_scad ? obj.doc_scad : mancante,
    cellulare: obj.cellulare
      ? obj.cellulare
      : win.cellulare
      ? win.cellulare
      : mancante,
    alimentari_allergie: allergie,
    alimentari_preferenze: alimentari_preferenze,
    alimentari_note: obj.note_alimentari ? obj.note_alimentari : "",
    gala: obj.galaTable ? obj.galaTable : win.galaTable ? win.galaTable : "",
    aeroporto: win.sigla_aeroporto ? win.sigla_aeroporto : "",
    req_dus: win.req_dus ? win.req_dus : "",
    note: obj.note ? obj.note : "",
    note_elisa: obj.note_elisa ? obj.note_elisa : "",
    turno: turno,
    hotel: hotel,
    volo_andata: volo_andata,
    volo_ritorno: volo_ritorno,
    prima_scelta: obj.prima_scelta ? obj.prima_scelta : false,
    verifica_dati_personali: obj.verifica_dati_personali
      ? obj.verifica_dati_personali
      : false,
    business: obj.business ? obj.business : false,
    link_pagamento: obj.link_pagamento ? obj.link_pagamento : false,
    assicurazione: obj.assicurazione ? obj.assicurazione : false,
    imposta_camera: obj.imposta_camera ? obj.imposta_camera : false,
    camera_condivisa: obj.camera_condivisa ? obj.camera_condivisa : "",
    camera_singola: obj.camera_singola ? obj.camera_singola : false,
    ageA: obj.nascita_data
      ? dayjs(win.turno === "A" ? "2024-09-13" : "2024-09-20").diff(
          dayjs(obj.nascita_data),
          "year"
        )
      : "nn",
    ageR: obj.nascita_data
      ? dayjs(win.turno === "A" ? "2024-09-20" : "2024-09-27").diff(
          dayjs(obj.nascita_data),
          "year"
        )
      : "nn",
  };
}

function toGuest(obj) {
  return {
    turno: obj.turno,
    email: obj.email,
    type:
      obj.email === obj.id
        ? obj.wa
          ? "Wealth Advisor"
          : "Family Banker"
        : "Accompagnatore",
    nome: obj.nome,
    cognome: obj.cognome,
    ordinatore: obj.ordinatore,
    nascita_data: formatDateIta(obj.nascita_data),
    ageA: obj.ageA,
    ageR: obj.ageR,
    nascita_stato: obj.nascita_stato,
    doc_stato: obj.doc_stato,
    doc_scad: formatDateIta(obj.doc_scad),
    gala: obj.gala ? obj.gala : "",
    cellulare: obj.cellulare,
    aeroporto: obj.aeroporto,
    req_dus: obj.req_dus,
    note: obj.note,
  };
}
function toGalaGuest(obj) {
  return {
    turno: obj.turno,
    email: obj.email,
    type: (obj.email === obj.id ? "Invitato" : "Accompagnatore").toUpperCase(),
    ordinatore: obj.ordinatore.toUpperCase(),
    nome: obj.nome.toUpperCase(),
    cognome: obj.cognome.toUpperCase(),
    nascita_data: formatDateIta(obj.nascita_data),
    alimentari_allergie: obj.alimentari_allergie.toUpperCase(),
    alimentari_preferenze: obj.alimentari_preferenze ? obj.alimentari_preferenze : "",
    alimentari_note: obj.alimentari_note.toUpperCase(),
    age: obj.ageA,
    gala: obj.gala ? obj.gala : "",
    cellulare: obj.cellulare,
    note: obj.note,
  };
}

function toWinCount(obj) {
  return {
    email: obj.email,
    codice_agente: obj.codice_agente.toString(),
    nome: obj.nome,
    cognome: obj.cognome,
    turno: obj.turno,
    maxAcc: obj.maxAcc,
    premio: obj.premio,
    aeroporto: obj.aeroporto,
    req_dus: obj.req_dus,
  };
}
function toWinTutto(obj) {
  let pref_camera = obj.preferenze_camera;
  let acc_adult = 0;
  let acc_child = 0;
  let acc_infant = 0;
  if (obj.lista_acc.length > 0) {
    obj.lista_acc.forEach((acc) => {
      if (acc.ageA > 11) {
        acc_adult += 1;
      } else if (acc.ageA > 2) {
        acc_child += 1;
      } else {
        acc_infant += 1;
      }
    });
  }
  return {
    email: obj.email,
    codice_agente: obj.codice_agente.toString(),
    nome: obj.nome,
    cognome: obj.cognome,
    genere: obj.genere,
    turno: obj.turno,
    pax: 1 + obj.lista_acc.length,
    acc_adult: acc_adult,
    acc_child: acc_child,
    acc_infant: acc_infant,
    cellulare: obj.cellulare,
    aeroporto: obj.aeroporto,
    note: obj.note,
    note_elisa: obj.note_elisa,
    note_mediolanum: obj.note_mediolanum,
    dus: obj.dus ? obj.dus : obj.req_dus ? "richiesta" : "",
    pref_camera: pref_camera,
    hotel: obj.hotel ? obj.hotel : "",
    room_number: obj.room_number ? obj.room_number : "",
    room_cat: obj.room_cat ? obj.room_cat : "",
    room_count: obj.n_of_rooms ? obj.n_of_rooms : "",
    pagamento_ricevuto: obj.pag_ricevuto
      ? (obj.pag_ricevuto === "no") | (obj.pag_ricevuto === "non pagante")
        ? obj.pag_ricevuto
        : formatDateIta(obj.pag_ricevuto)
      : "",
  };
}

function toConferma(obj) {
  let mancante = "nndd";
  let quota = obj.quota ? obj.quota : 0;
  let accompagnatori = obj.lista_acc
    ? obj.lista_acc.length
      ? obj.lista_acc.map((acc) => convertAcc(acc, obj))
      : []
    : [];
  let sigla = obj.codice_agente
    ? creaSiglaDaCodice(obj.codice_agente)
    : "no-sigla";
  let allergie = obj.alimentari_allergie
    ? obj.alimentari_allergie.join(", ")
    : [];
  let alimentari_preferenze = obj.alimentari_preferenze
    ? obj.alimentari_preferenze
    : [];
  let acc = obj.lista_acc ? obj.lista_acc.length : 0;
  return {
    codice_agente: obj.codice_agente ? obj.codice_agente : mancante,
    email: obj.email ? obj.email : mancante,
    nome: obj.nome ? obj.nome : mancante,
    cognome: obj.cognome ? obj.cognome : mancante,
    turno: obj.turno ? obj.turno : mancante,
    pax: 1 + acc,
    premio: obj.premio ? obj.premio : "no-premio",
    acc: acc,
    maxAcc: obj.max_accompagnatori ? obj.max_accompagnatori : 0,
    volo_andata: obj.volo_andata ? obj.volo_andata : "",
    volo_ritorno: obj.volo_ritorno ? obj.volo_ritorno : "",
    wa: obj.wa ? obj.wa : "",
    vip: obj.vip ? obj.vip : "",
    hotel: obj.hotel ? obj.hotel : "",
    n_of_rooms: obj.n_of_rooms ? obj.n_of_rooms : "",
    room_number: obj.room_number ? obj.room_number : "",
    room_type: obj.room_type ? obj.room_type : "",
    nascita_data: obj.nascita_data ? obj.nascita_data : mancante,
    nascita_comune: obj.nascita_comune ? obj.nascita_comune : mancante,
    nascita_provincia: obj.nascita_provincia ? obj.nascita_provincia : mancante,
    nascita_stato: obj.nascita_stato ? obj.nascita_stato : mancante,
    cellulare: obj.cellulare ? obj.cellulare : mancante,
    doc: obj.doc_tipo ? obj.doc_tipo : mancante,
    doc_stato: obj.doc_stato ? obj.doc_stato : mancante,
    doc_num: obj.doc_numero ? obj.doc_numero : mancante,
    doc_rilas: obj.doc_luogo ? obj.doc_luogo : mancante,
    doc_data_ril: obj.doc_data ? obj.doc_data : mancante,
    doc_scad: obj.doc_scad ? obj.doc_scad : mancante,
    cod_fiscale: obj.codice_fiscale ? obj.codice_fiscale : mancante,
    alimentari_allergie: allergie,
    alimentari_preferenze: alimentari_preferenze,
    alimentari_note: obj.note_alimentari ? obj.note_alimentari : "",
    fatt_indirizzo: obj.fatt_indirizzo ? obj.fatt_indirizzo : mancante,
    fatt_cap: obj.fatt_cap ? obj.fatt_cap : mancante,
    fatt_citta: obj.fatt_citta ? obj.fatt_citta : mancante,
    fatt_prov: obj.fatt_prov ? obj.fatt_prov : mancante,
    fatt_sdi: obj.fatt_sdi ? obj.fatt_sdi : mancante,
    fatt_pec: obj.fatt_pec ? obj.fatt_pec : mancante,
    partita_iva: obj.partita_iva ? obj.partita_iva : mancante,
    lista_acc: accompagnatori,
    agenda: obj.agenda ? obj.agenda : [],
    rinuncia: obj.rinuncia ? obj.rinuncia : "no",
    conferma: obj.viaggio_confermato
      ? formatDateIta(obj.viaggio_confermato)
      : "non confermato",
    quota: quota,
    quataAss: obj.quotaAss ? obj.quotaAss : 0,
    quotaFeeCard: obj.quotaFeeCard ? obj.quotaFeeCard : 0,
    noshow: obj.noshow ? obj.noshow : "",
    sigla_agente: sigla,
    pagamento: obj.pagamento ? obj.pagamento : "",
    pag_ricevuto: obj.conferma_di_pagamento
      ? formatDateIta(obj.conferma_di_pagamento)
      : obj.quota
      ? "no"
      : "non pagante",
    note: obj.note ? obj.note : "",
    note_mediolanum: obj.note_mediolanum ? obj.note_mediolanum : "",
  };
}
function toConfermaMed(obj) {
  let mancante = "nndd";
  let acc = obj.lista_acc ? obj.lista_acc.length : 0;
  return {
    codice_agente: obj.codice_agente ? obj.codice_agente : mancante,
    email: obj.email ? obj.email : mancante,
    nome: obj.nome ? obj.nome : mancante,
    cognome: obj.cognome ? obj.cognome : mancante,
    turno: obj.turno ? obj.turno : mancante,
    pax: 1 + acc,
    // max_accompagnatori: obj.max_accompagnatori ? obj.max_accompagnatori : 0,
    cellulare: obj.cellulare ? obj.cellulare : mancante,
    conferma: obj.viaggio_confermato
      ? formatDateIta(obj.viaggio_confermato)
      : mancante,
  };
}

export {
  convertWinner,
  toConferma,
  toWinCount,
  toWinTutto,
  creaSiglaDaCodice,
  formatDateEnShort,
  formatDateIta,
  convertAcc,
  arrToStringa,
  toGuest,
  toConfermaMed,
  toGalaGuest,
};
