<template>
  <v-card class="mb-6 card-shadow border-radius-xl py-4">
    <v-row no-gutters class="px-4">
      <v-col sm="4">
        <v-avatar
          :color="color"
          class="border-radius-xl mt-n8 shadow-dark"
          height="64"
          width="64"
        >
          <v-icon class="material-icons-round text-white" size="30">{{
            icon
          }}</v-icon>
        </v-avatar>
      </v-col>
      <v-col sm="8" class="text-end">
        <p class="text-h6 mb-0 text-capitalize text-body font-weight-bold">
          {{ title }}
        </p>
        <h4 class="text-h4 text-typo font-weight-bolder mb-0">{{ count }}</h4>
      </v-col>
    </v-row>
    <v-row no-gutters class="px-4">
      <v-col sm="4">
        <div class="first-button-container" v-if="download && ready">
          <xlsx-workbook>
            <xlsx-sheet
              :collection="sheet.data"
              v-for="sheet in sheets"
              :key="sheet.name"
              :sheet-name="sheet.name"
            />
            <xlsx-download :filename="calcDate + '.xlsx'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon large v-on="on" v-bind="attrs">
                    <v-icon size="40" :color="iconColor">mdi-download</v-icon>
                  </v-btn>
                </template>
                <span
                  >XLSX DOWNLOAD
                  {{
                    sottotitolo_scheda ? sottotitolo_scheda.toUpperCase() : ""
                  }}</span
                >
              </v-tooltip>
            </xlsx-download>
          </xlsx-workbook>
        </div>
        <v-progress-circular
          indeterminate
          :color="iconColor"
          v-else-if="download && !ready"
        ></v-progress-circular>
      </v-col>
      <v-col sm="4">
        <div class="first-button-container" v-if="table">
          <v-tooltip bottom v-if="ready">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon large v-on="on" v-bind="attrs" @click="openTable">
                <v-icon size="40" :color="iconColor"
                  >mdi-table-headers-eye</v-icon
                >
              </v-btn>
            </template>
            <span>Apri tabella {{ title }}</span>
          </v-tooltip>
          <v-progress-circular
            indeterminate
            :color="iconColor"
            v-else
          ></v-progress-circular>
          <v-dialog
            v-model="tableDialog"
            fullscreen
            transition="dialog-bottom-transition"
          >
            <base-datatable-full
              :tableBarColor="tableBarColor"
              :items="tableItems"
              :title="title"
              :subtitle="description"
              :headers="headers"
              dense
              @chiudi="closeTable"
            />
          </v-dialog>
        </div>
      </v-col>
    </v-row>
    <hr class="dark horizontal mt-3 mb-4" />
    <v-row class="px-4">
      <v-col cols="12">
        <p class="mb-0 text-body">
          <span class="ms-1">{{ description }}</span>
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import { calcDate } from "@/utilities/helpers.js";
export default {
  name: "Counter",
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  computed: {
    calcDate() {
      return calcDate(this.title);
    },
    sottotitolo_scheda() {
      return this.title.toLowerCase().replace(/ /g, "_");
    },
  },
  data() {
    return {
      tableDialog: false,
    };
  },
  methods: {
    openTable() {
      this.tableDialog = true;
    },
    closeTable() {
      this.tableDialog = false;
    },
  },
  props: {
    color: {
      type: String,
      default: "bg-gradient-info",
    },
    iconColor: {
      type: String,
      default: "info",
    },
    tableBarColor: {
      type: String,
      default: "bg-gradient-info",
    },
    download: {
      type: Boolean,
      default: false,
    },
    table: {
      type: Boolean,
      default: false,
    },
    tableItems: {
      type: Array,
      default: () => [],
    },
    sheets: {
      type: Array,
      default: () => [
        {
          name: "sheet1",
          data: [],
        },
      ],
    },
    icon: {
      type: String,
      default: "mdi-account",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    count: {
      type: [String, Number],
      default: "",
    },
    ready: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scoped>
.first-button-container {
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 16px; */
}
</style>
