<template>
  <v-app>
    <base-working></base-working>
    <v-main class="blank-pages auth-pages">
      <v-fade-transition mode="out-in">
        <!-- your content here -->
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "BlankLayout",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss">
.blank-pages {
  background-color: #93C7AF;
}
</style>
