<template>
  <v-card color="white" class="pa-4 white" height="100%" :min-width="roomWidth">
    <v-card-title>
      <v-app-bar dark absolute color="accent">
        <v-btn
          outlined
          tile
          class="mr-4"
          color="white"
          dark
          @click.stop="chiudi"
        >
          CHIUDI
        </v-btn>
        <strong>CENA DI GALA</strong>
        <v-spacer></v-spacer>
        <v-autocomplete
          :items="lSeat"
          prepend-inner-icon="mdi-magnify"
          v-model="foundWinner"
          no-data-text="Non ha ancora scelto il tavolo"
          label="Trova winner"
          outlined
          hide-details
          clearable
          item-text="n"
          return-object
          @change="colorTable"
          @input="showNumber"
          dense
        ></v-autocomplete>
        <xlsx-workbook class="ml-2">
          <xlsx-sheet
            :collection="sheet.data"
            v-for="sheet in sheets"
            :key="sheet.name"
            :sheet-name="sheet.name"
          />
          <xlsx-download :filename="calcDate() + '.xlsx'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="accent" v-on="on" v-bind="attrs">
                  <v-icon color="white">mdi-microsoft-excel</v-icon>
                  <span class="ml2">DOWNLOAD</span>
                </v-btn>
              </template>
              <span>SCARICA XLSX CENA DI GALA turno {{ turno }}</span>
            </v-tooltip>
          </xlsx-download>
        </xlsx-workbook>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          tile
          class="mr-4"
          color="white"
          dark
          @click.stop="chiudi"
        >
          CHIUDI
        </v-btn>
      </v-app-bar>
    </v-card-title>
    <v-card-text>
      <v-row no-gutter>
        <v-card color="white" height="80px" flat tile> </v-card>
      </v-row>
      <v-card rounded="0" color="grey lighten-2" class="pa-0" elevation="8">
        <v-card
          rounded="0"
          bordered
          style="border: 1px dashed #202967; background-color: #f5f5f5"
          class="pa-8"
        >
          <v-row no-gutters>
            <v-col cols="4" class="d-flex"> </v-col>
            <v-col cols="4">
              <v-card
                class="mx-8"
                color="grey lighten-2"
                flat
                rounded="0"
                height="60px"
              >
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T1.idTavolo"
                v-bind="taW.T1"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T2.idTavolo"
                v-bind="taW.T2"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T3.idTavolo"
                v-bind="taW.T3"
              />
              <v-card color="grey lighten-4" flat width="70px"></v-card>
            </v-col>
            <v-col cols="4">
              <v-card
                class="mx-8 text-center"
                color="grey lighten-2"
                flat
                rounded="0"
                height="100%"
              >
                <strong>STAGE</strong>
              </v-card>
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between">
              <v-card color="grey lighten-4" flat width="70px"></v-card>
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T42.idTavolo"
                v-bind="taW.T42"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T43.idTavolo"
                v-bind="taW.T43"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T44.idTavolo"
                v-bind="taW.T44"
                position="left"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4"> </v-col>
            <v-col cols="4">
              <v-card
                color="grey lighten-2"
                class="mx-8 text-center"
                flat
                rounded="0"
                height="45px"
              >
              </v-card>
            </v-col>
            <v-col cols="4"> </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T4.idTavolo"
                v-bind="taW.T4"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T5.idTavolo"
                v-bind="taW.T5"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T6.idTavolo"
                v-bind="taW.T6"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taR.TA.idTavolo"
                v-bind="taR.TA"
              />
            </v-col>
            <v-col cols="4">
              <v-card
                color="grey lighten-2"
                class="mx-8 text-center"
                flat
                rounded="0"
                height="100%"
                ><strong>DANCE FLOOR</strong>
              </v-card>
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taR.TB.idTavolo"
                v-bind="taR.TB"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T45.idTavolo"
                v-bind="taW.T45"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T46.idTavolo"
                v-bind="taW.T46"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T47.idTavolo"
                v-bind="taW.T47"
                position="left"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4"> </v-col>
            <v-col cols="4">
              <v-card color="transparent" flat rounded="0" height="45px">
              </v-card>
            </v-col>
            <v-col cols="4"> </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T7.idTavolo"
                v-bind="taW.T7"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T8.idTavolo"
                v-bind="taW.T8"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T9.idTavolo"
                v-bind="taW.T9"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taR.TC.idTavolo"
                v-bind="taR.TC"
              />
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between px-12">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taR.TD.idTavolo"
                v-bind="taR.TD"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taR.TE.idTavolo"
                v-bind="taR.TE"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taR.TF.idTavolo"
                v-bind="taR.TF"
              />
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taR.TG.idTavolo"
                v-bind="taR.TG"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T48.idTavolo"
                v-bind="taW.T48"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T49.idTavolo"
                v-bind="taW.T49"
                position="left"
              />
              <v-card color="grey lighten-4" flat width="70px"></v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card color="transparent" flat rounded="0" height="45px">
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T10.idTavolo"
                v-bind="taW.T10"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T11.idTavolo"
                v-bind="taW.T11"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T12.idTavolo"
                v-bind="taW.T12"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T13.idTavolo"
                v-bind="taW.T13"
              />
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between px-12">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T30.idTavolo"
                v-bind="taW.T30"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T31.idTavolo"
                v-bind="taW.T31"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T32.idTavolo"
                v-bind="taW.T32"
              />
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T50.idTavolo"
                v-bind="taW.T50"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T51.idTavolo"
                v-bind="taW.T51"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T52.idTavolo"
                v-bind="taW.T52"
                position="left"
              />
              <v-card color="grey lighten-4" flat width="70px"></v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card color="transparent" flat rounded="0" height="45px">
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T14.idTavolo"
                v-bind="taW.T14"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T15.idTavolo"
                v-bind="taW.T15"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T16.idTavolo"
                v-bind="taW.T16"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T17.idTavolo"
                v-bind="taW.T17"
              />
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between px-12">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T33.idTavolo"
                v-bind="taW.T33"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T34.idTavolo"
                v-bind="taW.T34"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T35.idTavolo"
                v-bind="taW.T35"
              />
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T53.idTavolo"
                v-bind="taW.T53"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T54.idTavolo"
                v-bind="taW.T54"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T55.idTavolo"
                v-bind="taW.T55"
                position="left"
              />
              <v-card color="grey lighten-4" flat width="70px"></v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card color="transparent" flat rounded="0" height="45px">
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T18.idTavolo"
                v-bind="taW.T18"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T19.idTavolo"
                v-bind="taW.T19"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T20.idTavolo"
                v-bind="taW.T20"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T21.idTavolo"
                v-bind="taW.T21"
              />
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between px-12">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T36.idTavolo"
                v-bind="taW.T36"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T37.idTavolo"
                v-bind="taW.T37"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T38.idTavolo"
                v-bind="taW.T38"
              />
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T56.idTavolo"
                v-bind="taW.T56"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T57.idTavolo"
                v-bind="taW.T57"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T58.idTavolo"
                v-bind="taW.T58"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taR.TS1.idTavolo"
                v-bind="taR.TS1"
                position="left"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card color="transparent" flat rounded="0" height="45px">
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T22.idTavolo"
                v-bind="taW.T22"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T23.idTavolo"
                v-bind="taW.T23"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T24.idTavolo"
                v-bind="taW.T24"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T25.idTavolo"
                v-bind="taW.T25"
              />
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between px-12">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T39.idTavolo"
                v-bind="taW.T39"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T40.idTavolo"
                v-bind="taW.T40"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T41.idTavolo"
                v-bind="taW.T41"
              />
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T59.idTavolo"
                v-bind="taW.T59"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T60.idTavolo"
                v-bind="taW.T60"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T61.idTavolo"
                v-bind="taW.T61"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taR.TS2.idTavolo"
                v-bind="taR.TS2"
                position="left"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card color="transparent" flat rounded="0" height="45px">
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T26.idTavolo"
                v-bind="taW.T26"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T27.idTavolo"
                v-bind="taW.T27"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T28.idTavolo"
                v-bind="taW.T28"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T29.idTavolo"
                v-bind="taW.T29"
              />
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between px-12"> </v-col>
            <v-col cols="4" class="d-flex justify-space-between">
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T62.idTavolo"
                v-bind="taW.T62"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taW.T63.idTavolo"
                v-bind="taW.T63"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taR.S4.idTavolo"
                v-bind="taR.S4"
                position="left"
              />
              <gala-tavolo-admin
                :maxPersone="12"
                :width="tableWidth"
                :height="tableHeight"
                :key="taR.TS3.idTavolo"
                v-bind="taR.TS3"
                position="left"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-card-text>
    <GalaSettingsAdmin :turno="turno" />
  </v-card>
</template>

<script>
import GalaTavoloAdmin from "@/components/admin/gala/GalaTavoloAdmin.vue";
import GalaSettingsAdmin from "@/components/admin/gala/GalaSettingsAdmin.vue";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";

export default {
  name: "GalaRoomAdmin",
  components: {
    GalaTavoloAdmin,
    GalaSettingsAdmin,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data: () => ({
    getPartyDialog: false,
    foundWinner: "",
    colered: "",
    party: [],
    tableToSave: "",
    saveTable: false,
  }),
  computed: {
    color() {
      return this.$vuetify.isDark ? "white" : "grey lighten-5";
    },
    tableWidth() {
      return this.$vuetify.breakpoint.lgAndUp ? "70px" : "60px";
    },
    tableHeight() {
      return this.$vuetify.breakpoint.lgAndUp ? "70px" : "60px";
    },
    roomWidth() {
      return this.$vuetify.breakpoint.lgAndUp ? "1280px" : "1000px";
    },
    tavoli() {
      let tavoli = this.$store.getters[`participants/tavoliGala${this.turno}`];
      function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }
      return tavoli.sort(dynamicSort("tavolo"));
    },
    sheets() {
      return [{ name: "TAVOLI " + this.turno, data: this.tavoli }];
    },
    taW() {
      return this.$store.getters[`gala/getGalaW${this.turno}`];
    },
    taR() {
      return this.$store.getters[`gala/getGalaR${this.turno}`];
    },
    lWin() {
      return this.$store.getters[`gala/getGalaLW${this.turno}`];
    },
    lSeat() {
      function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }
      let lseat = this.$store.getters[`gala/getGalaTW${this.turno}`];
      return lseat.sort(dynamicSort("n"));
    },
  },
  methods: {
    chiudi() {
      this.$emit("chiudi");
    },
    showNumber() {
      if (this.foundWinner) {
        this.$swal.fire({
          title:
            this.foundWinner.n + " - TAVOLO " + this.foundWinner.t.slice(1),
          // title:
          //   this.foundWinner.n + " - TAVOLO " + this.foundWinner.t.slice(1),
          icon: "success",
          timer: 3500,
          // position: "top",
          showConfirmButton: false,
          showCancelButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          closeOnClickOutside: false,
          backdrop: false,
          // toast: true,
        });
      }
    },
    colorTable() {
      if (this.foundWinner) {
        if (this.colored) {
          this.taW[this.colored].tabColor = "";
        }
        this.colored = this.foundWinner.t;
        this.taW[this.foundWinner.t].tabColor = "purple";
      } else {
        this.taW[this.colored].tabColor = "";
      }
    },

    calcDate() {
      let options = {
        year: "2-digit",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      let called = new Date().toLocaleString("it-IT", options);
      return "WINNERS2023-gala-" + this.turno + "-" + called;
    },
  },
  props: {
    turno: {
      type: String,
      default: "A",
    },
  },
};
</script>
