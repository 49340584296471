<template>
  <v-row no-gutters>
    <v-col>
      <p
        v-if="titolo"
        class="text-sm mb-1 text-capitalize text-body font-weight-bold"
      >
        {{ titolo }}
      </p>
      <v-autocomplete
        v-bind="$attrs"
        v-on="$listeners"
        :label="label"
        :items="items"
        outlined
        clearable
        dense
        :hide-details="hide ? true : bhint ? true : false"
        class="text-h6 text-typo font-weight-bolder mb-0"
      >
      </v-autocomplete>

      <div
        v-if="bhint"
        class="text-end text-capitalize text-xs text-secondary font-weight-light"
      >
        {{ bhint }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "AutocompleteFieldLine",
  props: {
    titolo: {
      type: [String, null],
      default: null,
    },
    label: {
      type: [String, null],
      default: null,
    },
    items: {
      type: [Array],
      default: () => [],
    },
    bottom: {
      type: [Boolean],
      default: false,
    },
    bhint: {
      type: [String, null],
      default: null,
    },
    hide: {
      type: [Boolean],
      default: false,
    },
  },
  computed: {},
  mounted() {},
  data: () => ({
    text: "",
  }),
  methods: {
    bubbleUp(data) {
      this.$emit("update", data);
    },
  },
};
</script>
