import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "@/firebase/init";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import UserLayout from "../views/Layout/UserLayout.vue";
import AuthLayout from "../views/Layout/AuthBasicLayout.vue";
import BlankLayout from "../views/Layout/BlankLayout.vue";

Vue.use(VueRouter);
const BlankHome = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/BlankHome.vue");
const TestParam = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/TestParam.vue");
const Reservations = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Multiprojects/Reservations.vue"
  );
const Reports = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Reports.vue");
const People = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/People.vue");
const Hotellerie = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Hotellerie.vue");
const Settings = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Settings.vue");
const SetQuote = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/SetQuote.vue");
const SetContent = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/SetContent.vue");
const AdActivities = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/AdActivities.vue"
  );
const ActivitiesDownloads = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/ActivitiesDownloads.vue"
  );
const AgendaCalendar = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/AgendaCalendar.vue"
  );
const NewActivity = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/NewActivity.vue");
const Privilegi = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Privilegi.vue");
const Statistiche = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Statistiche.vue");
const AdminRootPage = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/AdminRootPage.vue"
  );
const Galadmin = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Galadmin.vue");
const Home = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/User/Home.vue");
const Registrazione = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/User/Registrazione.vue"
  );
const Destinazione = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/User/Destinazione.vue");
const Gala = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/User/Gala.vue");
const InfoUtili = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/User/InfoUtili.vue");
const Conferma = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/User/ConfermaViaggio.vue"
  );
const Profile = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/User/Profile.vue");
const Activities = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/User/Activities.vue");
const Agenda = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/User/Agenda.vue");
const Login = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Auth/LoginFullScreen.vue"
  );
const LoginPwless = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/LoginPwless.vue");
const LoginAdmin = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/LoginAdmin.vue");
const Confirmation = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/Confirmation.vue");
const About = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/About.vue");

function authGuard(to, from, next) {
  const allowedParams = [
    "rate",
    "dorotea",
    "DoRoTeA",
    "btgfs4pvnsmzvgsiax87rsgxt",
  ];
  const verificable = ["rate"];
  // Ottieni il parametro dall'URL
  const param = to.params["project"];
  // console.log(param);
  // Controlla se il parametro  è presente nell'elenco
  if (allowedParams.indexOf(param) === -1) {
    // Nega l'accesso e reindirizza
    next("/");
  } else {
    if (to.name === "LoginPwless") {
      if (verificable.indexOf(param) === -1) {
        next("/" + param);
      } else {
        next();
      }
    }
    next();
  }
}
let adminPages = {
  path: "/admin",
  component: DashboardLayout,
  redirect: "/admin/reports",
  name: "Admin",
  meta: {
    groupName: "admin",
    requiresEditor: true,
  },
  children: [
    {
      path: "/admin/reports",
      name: "Reports",
      component: Reports,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/people",
      name: "People",
      component: People,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/stats",
      name: "Statistiche",
      component: Statistiche,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/galadmin",
      name: "Galadmin",
      component: Galadmin,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/privilegi",
      name: "privilegi",
      component: Privilegi,
      meta: {
        groupName: "admin",
        requiresAdmin: true,
      },
    },
    {
      path: "/admin/hotellerie",
      name: "hotellerie",
      component: Hotellerie,
      meta: {
        groupName: "admin",
        requiresAdmin: true,
      },
    },
    {
      path: "/admin/settings",
      name: "settings",
      component: Settings,
      meta: {
        groupName: "admin",
        requiresAdmin: true,
      },
    },
    {
      path: "/admin/setcontent",
      name: "setcontent",
      component: SetContent,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/setquote",
      name: "setquote",
      component: SetQuote,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/activities",
      name: "adactivities",
      component: AdActivities,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/activitiesdload",
      name: "activitiesdload",
      component: ActivitiesDownloads,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/calendar",
      name: "AgendaAdmin",
      component: AgendaCalendar,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/nuovaattivita",
      name: "NewActivity",
      component: NewActivity,
      meta: {
        groupName: "admin",
        requiresAdmin: true,
      },
    },
  ],
};
let userPages = {
  path: "/user",
  redirect: "/user/home",
  component: UserLayout,
  name: "User",
  children: [
    {
      path: "/user/home",
      name: "Home",
      component: Home,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/profile",
      name: "Profile",
      component: Profile,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/destinazione",
      name: "Destinazione",
      component: Destinazione,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/gala",
      name: "Gala",
      component: Gala,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/registrazione",
      name: "Registrazione",
      component: Registrazione,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/activities",
      name: "Activities",
      component: Activities,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/agenda",
      name: "Agenda",
      component: Agenda,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/informazioni",
      name: "InfoUtili",
      component: InfoUtili,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/conferma",
      name: "Conferma",
      component: Conferma,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
  ],
};

const routes = [
  {
    path: "/",
    component: BlankLayout,
    children: [
      {
        path: "",
        name: "BlankHome",
        component: BlankHome,
        meta: {
          groupName: "Authpages",
        },
      },
    ],
  },
  {
    path: "/testparam",
    component: BlankLayout,
    children: [
      {
        path: "/testparam/:project",
        name: "TestParam",
        component: TestParam,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
        },
      },
    ],
  },
  {
    path: "/:project/reservations",
    component: BlankLayout,
    children: [
      {
        path: "/:project/reservations",
        name: "Reservations",
        component: Reservations,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
        },
      },
    ],
  },
  {
    path: "/:project/admin",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "AdminPage",
        component: AdminRootPage,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
          requiresEditor: true,
        },
      },
    ],
  },
  {
    path: "/:project/",
    component: AuthLayout,
    children: [
      {
        path: "",
        name: "Login",
        component: Login,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
        },
      },
      {
        path: "/:project/about",
        name: "About",
        component: About,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
        },
      },
      {
        path: "/:project/adminlogin",
        name: "AdminLogin",
        component: LoginAdmin,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
        },
      },
      {
        path: "/:project/llinkvalidation",
        name: "LoginPwless",
        component: LoginPwless,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
        },
      },
      {
        path: "/:project/confirmed",
        name: "Confirmation",
        component: Confirmation,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
        },
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  adminPages,
  userPages,
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //controllo se la route chiede autenticazione
  if (to.matched.some((rec) => rec.meta.requiresAuth)) {
    //controllo se user è autenticato
    let user = auth.currentUser;
    if (user) {
      //se user esiste sono autenticato quindi posso andare a next
      return next();
    } else {
      //se falso non sono autenticato quindi vengo reindirizzato a Home
      return next(from);
    }
  } else if (to.matched.some((rec) => rec.meta.requiresAdmin)) {
    // come prima ma qui viene richiesto che user sia anche Admin
    let user = auth.currentUser;
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        if (idTokenResult.claims.admin) {
          if (to.name === "Login") {
            //se è admin MA CHIEDE LOGIN deve andare a Reports
            return next({ name: "AdminPage" });
          }
          //se è admin può andare a next
          else return next();
        } else if (idTokenResult.claims.editor) {
          //se è editor deve andare a People
          return next({ name: "AdminLogin" });
        } else {
          //redirect a Profilo perché ho già controllato che user esista
          return next({ name: "Login" });
        }
      });
    } else {
      return next({ name: "Login" });
    }
  } else if (to.matched.some((rec) => rec.meta.requiresEditor)) {
    // come prima ma qui viene richiesto che user sia editor
    let user = auth.currentUser;
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        if (idTokenResult.claims.editor || idTokenResult.claims.admin) {
          //se è admin o editor può andare a next
          if (to.name === "Login") {
            if (idTokenResult.claims.admin) {
              return next({ name: "AdminPage" });
            } else return next({ name: "AdminPage" });
          } else return next();
        } else {
          //redirect a Home perché ho già controllato che user esista
          return next({ name: "Login" });
        }
      });
    } else {
      return next({ name: "Login" });
    }
  } else if (to.matched.some((rec) => rec.meta.requiresFinance)) {
    // come prima ma qui viene richiesto che user sia editor
    let user = auth.currentUser;
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        if (
          // (user.email.includes("@adisanto.com")) ||
          (idTokenResult.claims.editor && user.email.includes("@mutika.it")) ||
          idTokenResult.claims.admin
        ) {
          //se è admin o editor mutika può andare a next
          return next();
        } else if (idTokenResult.claims.editor) {
          //se è editor non mutika deve andare a People
          return next({ name: "People" });
        } else {
          //redirect a Home perché ho già controllato che user esista
          return next({ name: "Home" });
        }
      });
    } else {
      return next({ name: "Login" });
    }
  } else {
    //questa route non chiede autenticazione, ma se user è autenticato non può andare a Login
    let user = auth.currentUser;
    if (user && to.name === "Login") {
      user.getIdTokenResult().then((idTokenResult) => {
        if (idTokenResult.claims.admin) {
          return next({ name: "Reports" });
        } else if (idTokenResult.claims.editor) {
          return next({ name: "People" });
        } else return next({ name: "Home" });
      });
    } else {
      return next();
    }
  }
});
// router.beforeEach((to, from, next) => {
//   //controllo se la route chiede autenticazione
//   if (to.matched.some((rec) => rec.meta.requiresAuth)) {
//     //controllo se user è autenticato
//     let user = auth.currentUser;
//     if (user) {
//       //se user esiste sono autenticato quindi posso andare a next
//       return next();
//     } else {
//       //se falso non sono autenticato quindi vengo reindirizzato a Home
//       return next(from);
//     }
//   } else if (to.matched.some((rec) => rec.meta.requiresAdmin)) {
//     // come prima ma qui viene richiesto che user sia anche Admin
//     let user = auth.currentUser;
//     if (user) {
//       user.getIdTokenResult().then((idTokenResult) => {
//         if (idTokenResult.claims.admin) {
//           if (to.name === "Login") {
//             //se è admin MA CHIEDE LOGIN deve andare a Reports
//             return next({ name: "Reports" });
//           }
//           //se è admin può andare a next
//           else return next();
//         } else if (idTokenResult.claims.editor) {
//           //se è editor deve andare a People
//           return next({ name: "People" });
//         } else {
//           //redirect a Profilo perché ho già controllato che user esista
//           return next({ name: "Home" });
//         }
//       });
//     } else {
//       return next({ name: "Login" });
//     }
//   } else if (to.matched.some((rec) => rec.meta.requiresEditor)) {
//     // come prima ma qui viene richiesto che user sia editor
//     let user = auth.currentUser;
//     if (user) {
//       user.getIdTokenResult().then((idTokenResult) => {
//         if (idTokenResult.claims.editor || idTokenResult.claims.admin) {
//           //se è admin o editor può andare a next
//           if (to.name === "Login") {
//             if (idTokenResult.claims.admin) {
//               return next({ name: "Reports" });
//             } else return next({ name: "People" });
//           } else return next();
//         } else {
//           //redirect a Home perché ho già controllato che user esista
//           return next({ name: "Home" });
//         }
//       });
//     } else {
//       return next({ name: "Login" });
//     }
//   } else if (to.matched.some((rec) => rec.meta.requiresFinance)) {
//     // come prima ma qui viene richiesto che user sia editor
//     let user = auth.currentUser;
//     if (user) {
//       user.getIdTokenResult().then((idTokenResult) => {
//         if (
//           // (user.email.includes("@adisanto.com")) ||
//           (idTokenResult.claims.editor && user.email.includes("@mutika.it")) ||
//           idTokenResult.claims.admin
//         ) {
//           //se è admin o editor mutika può andare a next
//           return next();
//         } else if (idTokenResult.claims.editor) {
//           //se è editor non mutika deve andare a People
//           return next({ name: "People" });
//         } else {
//           //redirect a Home perché ho già controllato che user esista
//           return next({ name: "Home" });
//         }
//       });
//     } else {
//       return next({ name: "Login" });
//     }
//   } else {
//     //questa route non chiede autenticazione, ma se user è autenticato non può andare a Login
//     let user = auth.currentUser;
//     if (user && to.name === "Login") {
//       user.getIdTokenResult().then((idTokenResult) => {
//         if (idTokenResult.claims.admin) {
//           return next({ name: "Reports" });
//         } else if (idTokenResult.claims.editor) {
//           return next({ name: "People" });
//         } else return next({ name: "Home" });
//       });
//     } else {
//       return next();
//     }
//   }
// });

export default router;
